.chat-session {
  background-color: #111; /* Dark background for the chat session */
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto; /* Center it horizontally */
  max-height: calc(100vh - 100px);
  
}

  
.messages {
  overflow-y: auto; /* Allows scrolling */
  flex-direction: column; /* Ensure messages are stacked vertically */
  /* Remove height: 100% if there's a max-height on .chat-session */
  margin-bottom: 50px;
  /* You might need a max-height here if .chat-session is flexing beyond the viewport, e.g., */
  max-height: calc(100vh - 50px); /* Adjust the 100px based on other elements' total height */
  flex-grow: 1; 


}

  
.message {
  position: relative;
  padding-left: 40px; /* Make room for the avatar on the left */
  margin-top: 10px; /* Ensure space between messages */

  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 8px 12px;
  border-radius: 16px;
  max-width: 80%; /* Allows some space on sides for tail effect if needed */
}
.message-header {
  font-weight: bold; /* Make the user name bold */
  margin-bottom: 4px; /* Space between the name and the message text */
}

.message.me {
  display: flex;
  align-items: center;
  padding-left: 12px; /* No avatar on the left for user messages */
padding-right: 40px; /* Make room for the avatar on the right */
}


.message.ai {
  background-color: #e5e5ea; /* Light color for bot messages */
  align-self: flex-start;
  margin-left: 10px; /* Give some space for the message tail if you have one */
  color: black; /* Text color for bot messages */
}


  
.input-container {
  position: fixed; /* Fix position to the bottom of the screen */
  bottom: 0; /* Align to the bottom */
  right: 0; /* Align to the right to stretch across the screen */
  display: flex;
  width: 49%;
  /* align right */

  padding: 10px; /* Add some padding */
  background-color: #111; /* Match the chat session background color */
  z-index: 100; /* Make sure it's above other content */
}

.chat-input {
  flex-grow: 1;
  border-radius: 20px;
  padding: 10px;
  margin-right: 10px;
}
  
  .send-button {
    background-color: #555; /* Bootstrap primary color for send button */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #555; /* Slightly darker on hover */
  }
  @media (max-width: 767px) {
    .input-container {
      width: 95%;
    }
    .chat-input {
      font-size: 16px; /* Ensure this is set for smaller devices too */
    }
    
  }
